import React from 'react';
import { useLocation } from '@reach/router';
import { graphql, PageProps } from 'gatsby';
import { HiArrowNarrowRight } from '@meronex/icons/hi/';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import {
  Box,
  HStack,
  Heading,
  Icon,
  Button,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo/seo';
import { ISiteMetadata } from '../hooks/useSiteMetadata';
import ComponentMatch from '../components/component-match/component-match';
import { ICardDataQuery } from '../components/gallery-section/gallery-section';
import SideNavigation from '../components/side-navigation/side-navigation';

interface PageQueryProps extends ISiteMetadata {
  contentfulPage: {
    id: string;
    slug: string;
    title?: string;
    metaTitle?: string;
    metaDescription?: string;
    metaImage?: {
      metaImageTitle?: string;
      metaImageDescription?: string;
      metaImage?: IGatsbyImageData;
    };
    sections: any;
    sideNavigation: any;
    noIndexNoFollow: boolean;
    galleryWeight: number;
  };
  galleryNodes: ICardDataQuery;
}

const Page = ({
  data: {
    contentfulPage: {
      id,
      slug,
      title,
      metaTitle,
      metaDescription,
      noIndexNoFollow,
      metaImage,
      sections,
      sideNavigation
    },
    galleryNodes: { edges: galleryNodes }
  },
  pageContext,
  path
}: PageProps<PageQueryProps>) => {
  const location = useLocation();

  return (
    <Layout isShowFooter={true}>
      {/* <Layout isShowFooter={!sideNavigation}> */}
      <SEO
        title={metaTitle}
        description={metaDescription}
        image={metaImage?.metaImage?.images?.fallback?.src}
        pathname={path}
        lang={'en'}
        noIndexNoFollow={noIndexNoFollow}
      />

      {/* Side nav */}
      {sideNavigation && (
        <Box as="main">
          <Box
            bg={useColorModeValue('background50', 'blue.400')}
            borderBottomWidth={1}
            borderBottomColor={useColorModeValue(
              'background100',
              'brandSubtle'
            )}
          >
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              alignItems={{ base: 'flex-start', sm: 'center' }}
              mx="auto"
              justifyContent="space-between"
              maxWidth="7xl"
              px={{
                base: 8,
                lg: 4
              }}
              py={4}
            >
              <HStack spacing="3">
                <Text fontSize="sm">Training Library</Text>
              </HStack>
              <HStack spacing="3" display={{ base: 'none', lg: 'inherit' }}>
                <Button
                  as={GatsbyLink}
                  to="/blog"
                  variant="ghost"
                  size="sm"
                  className="group"
                >
                  <HStack as="dt">
                    <Text color={useColorModeValue('gray.900', 'white')}>
                      Blog
                    </Text>
                    <Box
                      fontSize="md"
                      as={HiArrowNarrowRight}
                      transition="all 0.2s"
                      _groupHover={{
                        color: useColorModeValue('sec.600', 'inherit'),
                        transform: 'translateX(2px)'
                      }}
                    />
                  </HStack>
                </Button>

                <Button
                  as="a"
                  href="https://www.youtube.com/channel/UCOCC_XWXvT-z3WZZ9RDigGg/featured"
                  variant="ghost"
                  size="sm"
                  className="group"
                >
                  <HStack as="dt">
                    <Text color={useColorModeValue('gray.900', 'white')}>
                      Video Libary
                    </Text>
                    <Box
                      fontSize="md"
                      as={HiArrowNarrowRight}
                      transition="all 0.2s"
                      _groupHover={{
                        color: useColorModeValue('sec.600', 'inherit'),
                        transform: 'translateX(2px)'
                      }}
                    />
                  </HStack>
                </Button>
              </HStack>
            </Stack>
          </Box>
          <Box
            display={{ base: 'block', md: 'grid' }}
            gridTemplateColumns="auto 1fr"
            gridGap="2rem"
            mx="auto"
            maxWidth="7xl"
            minHeight="70vh"
          >
            <Box
              mb={{ base: '2rem', md: '0' }}
              mx={{
                base: 8,
                lg: 2
              }}
              borderBottomWidth={{ base: 2, md: 0 }}
            >
              <SideNavigation data={sideNavigation} />
            </Box>
            <Box>
              {sections.map(section => (
                <ComponentMatch
                  data={section}
                  galleryNodes={galleryNodes}
                  key={section.id}
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}

      {/* No side nav */}
      {!sideNavigation && (
        <Box
          as="main"
          display="block"
          gridTemplateColumns="auto 1fr"
          gridGap="inherit"
          mx="inherit"
          maxWidth="inherit"
        >
          <Box>
            {sections.map(section => (
              <ComponentMatch
                data={section}
                galleryNodes={galleryNodes}
                key={section.id}
              />
            ))}
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query PageQuery($slug: String!, $galleryCategories: [String]!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      id
      slug
      title
      metaTitle
      metaDescription
      metaImage {
        metaImageTitle: title
        metaImageDescription: description
        metaImage: gatsbyImageData(width: 1400, layout: FIXED)
      }
      noIndexNoFollow
      sections {
        __typename
        ... on Node {
          id
        }
        ...BasicSection
        ...BlogPostsSection
        ...CalendlySection
        ...ContactSection
        ...FaqSection
        ...FeatureGridSection
        ...GallerySection
        ...HeroSection
        ...LogoSection
        ...MapSection
        ...PricingSection
        ...ProblemAndSolutionSection
        ...SideBySideSection
        ...StatsSection
        ...TestimonialSection
        ...TextSection
        ...TextColumnsSection
        ...UseCasesSection
      }
      sideNavigation {
        ...SideNavigation
      }
    }
    galleryNodes: allContentfulPage(
      filter: {
        isDummyPage: { ne: true }
        galleryCategories: { elemMatch: { name: { in: $galleryCategories } } }
      }
      sort: { fields: [galleryWeight], order: ASC }
    ) {
      edges {
        node {
          id
          isDummyPage
          slug
          metaTitle
          metaDescription
          metaImage {
            metaImageTitle: title
            metaImageDescription: description
            metaImageFluid: gatsbyImageData(width: 1400, layout: FULL_WIDTH)
          }
          galleryCategories {
            name
          }
        }
      }
    }
  }
`;

export default Page;
